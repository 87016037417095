@import '@scss/var.scss';

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html body {
  color: $title;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: PingFang SC, system-ui, -apple-system, Helvetica Neue, Helvetica,
    Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
  background-color: #fff;
}

html,
body,
#root {
  height: 100%;
}

:root {
  --ratio-value: 1;
}

a {
  color: $primary;
  text-decoration: none;
}
// 禁止长按选中
body,
html {
  -webkit-touch-callout: none; /*系统默认菜单被禁用*/
  -webkit-user-select: none; /*webkit浏览器*/
  -khtml-user-select: none; /*早期浏览器*/
  -moz-user-select: none; /*火狐*/
  -ms-user-select: none; /*IE10*/
  user-select: none;
}

input,
textarea {
  user-select: auto;
  -webkit-user-select: auto;
}

body,
h1,
h3,
p {
  margin: 0;
  padding: 0;
}

button {
  display: block;
  outline: 0 none;
  padding: 0;
  border-radius: 8px;
  -webkit-appearance: none;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;
  color: #000;
  background-color: #fff;
  outline: none;
  border: 0;
}

input {
  display: block;
  margin: 0;
  padding: 0;
  line-height: inherit;
  text-align: left;
  background-color: transparent;
  resize: none;
  color: #333;
  /* 去除默认边框 */
  border: none;
  /* 去除选中或聚焦边框 */
  outline: none;
  /* 去除聚焦边框 */
  // &:focus-visible {
  // outline: none;
  // }
}

textarea {
  line-height: 6.8vw;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
  overflow: visible;
  display: block;
  resize: none;
  word-break: break-word;
  word-wrap: break-word;
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.clearfix {
  &::after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
}
