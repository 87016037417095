@import '@scss/var.scss';

:root:root {
  --adm-color-primary: $primary;
  --adm-color-text: $title;

  --adm-font-family: PingFang SC, system-ui, -apple-system, Helvetica Neue,
    Helvetica, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
}

.adm-auto-center-content {
  font-size: 14px;
}

.adm-toast-mask .adm-toast-main-text {
  padding: 12px 15px;
}
